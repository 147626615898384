<template>
  <div>
    <el-row :gutter="24" class="mt20">
      <el-col :span="24">
        <div class="page-header panel">
          <h3 class="title">Одоо аваад дараа төл</h3>
          <div class="action-section">
            <router-link to="/add-late-pay-step">
              <el-button
                type="success"
                size="mini"
                icon="el-icon-plus"
                v-if="!mArrayIncludes(role, ['CC', 'TL', 'driverShow'])"
                >Нэмэх</el-button
              >
            </router-link>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" :offset="0">
        <div class="panel">
          <el-table
            size="mini"
            :data="latePaySteps"
            v-loading="isDataLoading"
            style="width: 100%"
          >
            <el-table-column prop="id" width="40"> </el-table-column>
            <el-table-column
              width="140px"
              prop="orders_amount"
              label="Нийт захиалгын дүн"
            >
              <span slot-scope="scope">
                {{ mformatPrice(scope.row.orders_amount) }}₮
              </span>
            </el-table-column>
            <el-table-column
              width="140px"
              prop="interest_amount"
              label="Одоо төлөх дүн"
            >
              <span slot-scope="scope">
                {{ mformatPrice(scope.row.interest_amount) }}₮
              </span>
            </el-table-column>
            <el-table-column width="90px" prop="loan_amount" label="Купоны дүн">
              <span slot-scope="scope">
                {{ mformatPrice(scope.row.loan_amount) }}₮
              </span>
            </el-table-column>
            <el-table-column
              width="200px"
              prop="last_order_date_limit"
              label="Захиалгын хоногийн лимит"
            ></el-table-column>
            <el-table-column
              width="150px"
              prop="min_order_count"
              label="Бодох захиалгын тоо"
            ></el-table-column>
            <el-table-column prop="zip" label="Хэрэглэгдэх эсэх" fixed="right">
              <template slot-scope="scope">
                <el-switch
                  :disabled="mArrayIncludes(role, ['driverShow'])"
                  v-model="scope.row.is_available"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="updateStatus(scope.row, 'is_available')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="Үйлдэл" fixed="right">
              <template slot-scope="scope">
                <el-button
                  :disabled="mArrayIncludes(role, ['CC', 'TL', 'driverShow'])"
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="getLatePayStepById(scope.row.id)"
                  >Засах</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="is_deleted" label="Устгах" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-edit"
                  @click="deleteLatePayStep(scope.row.id)"
                  >Устгах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="text-right"
            background
            @size-change="sizeChange"
            @current-change="setPage"
            :current-page.sync="currentPage"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next"
            :total="pageCounts"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <el-select
          v-model="filterValue"
          @change="selectFilter"
          placeholder="Select"
          style="margin-top: 20px;"
        >
          <el-option
            v-for="item in filterLabel"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-input
          style="margin-top: 20px;"
          placeholder="Утасны дугаараар хайх"
          v-model="numberSearch"
        ></el-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px; margin-bottom: 80px;">
      <el-col :span="24" :offset="0">
        <div class="panel">
          <el-table
            size="mini"
            :data="loanAllData"
            v-loading="loading"
            style="width: 100%"
          >
            <el-table-column prop="granted_date" label="granted_date">
            </el-table-column>
            <el-table-column
              prop="expire_date"
              label="expire_date"
            ></el-table-column>
            <el-table-column prop="User.phone" label="phone"> </el-table-column>
            <el-table-column prop="loan_status" label="loan_status">
            </el-table-column>
            <el-table-column prop="loan_amount" label="loan_amount">
              <span slot-scope="scope">
                {{ mformatPrice(scope.row.loan_amount) }}₮
              </span>
            </el-table-column>
            <el-table-column prop="usage" label="usage">
              <span slot-scope="scope">
                {{ mformatPrice(scope.row.usage) }}₮
              </span>
            </el-table-column>
            <el-table-column prop="UserAccount.balance" label="balance">
              <span slot-scope="scope">
                {{ mformatPrice(scope.row.UserAccount.balance) }}₮
              </span>
            </el-table-column>
            <el-table-column label="Үйлдэл" fixed="right">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.is_change === true ? false : true"
                  type="primary"
                  size="mini"
                  @click="setOneItem(scope.row)"
                  >change</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="text-right"
            background
            @current-change="setLoanPage"
            layout="prev, pager, next"
            :total="loanTotal"
          >
          </el-pagination>
        </div>
      </el-col>
      <el-dialog
        :title="dialogOneItem.id"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <p>Та ямар төлөвтэй болгохоо сонгоно уу</p>
        <el-select v-model="dialogSelectValue" placeholder="Төлөв сонгох">
          <el-option
            v-for="item in dialogOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <p style="margin-top: 20px">Та balance хасах үнийн дүнгээ оруулна уу</p>
        <el-input v-model="input" placeholder="Үнийн дүн оруулах">
          <template #append>₮</template>
        </el-input>
        <p style="margin-top: 20px">Та өөрчилж буй шалтгаанаа бичнэ үү</p>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="өөрчилж буй шалтгаанаа бичнэ үү"
          v-model="textarea"
        >
        </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button
            :disabled="!input || !textarea || !dialogSelectValue"
            type="primary"
            @click="loanUpdate"
            >Confirm</el-button
          >
        </span>
      </el-dialog>
    </el-row>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
import { arrayIncludes, formatPrice } from "../helpers/helper";
import { getGroups, getUserName } from "../utils/auth";
export default {
  name: "DriverList",
  async created() {
    getUserName().then(name => {
      this.userName = name.attributes.email;
    });
    this.role = await getGroups();
    this.getLatePaySteps();
    this.getAllLoan();
  },
  watch: {
    numberSearch: function(newSearchValue) {
      this.getAllLoan(newSearchValue);
    }
  },
  data() {
    return {
      size: 10,
      input: "",
      textarea: "",
      dialogVisible: false,
      userName: "",
      filterLabel: [
        {
          label: "GRANTED"
        },
        {
          label: "EXPIRED"
        },
        {
          label: "CANCELLED"
        },
        {
          label: "ALL"
        }
      ],
      filterValue: "ALL",
      dialogOption: [
        {
          value: "PAID_OFF",
          label: "PAID_OFF"
        },
        {
          value: "CANCELLED",
          label: "CANCELLED"
        }
      ],
      dialogSelectValue: "",
      isDataLoading: false,
      loading: false,
      numberSearch: "",
      latePaySteps: [],
      dialogOneItem: {},
      loanAllData: [],
      loanTotal: 0,
      loanPage: 0,
      pageCounts: 0,
      currentPage: 1,
      pageSize: 50,
      page: 1,
      disabled: 0,
      role: []
    };
  },
  methods: {
    mformatPrice(value) {
      return formatPrice(value);
    },
    updateStatus(data, type) {
      this.$confirm("Та төлөв өөрчлөхдөө итгэлтэй байна уу?", "Анхааруулга", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          if (type === "is_active") {
            service
              .updateLatePayStep(data.id, { is_active: data.is_active })
              .then(res => {
                if (res?.data?.status === "success") {
                  this.getLatePaySteps();
                  this.$notify({
                    title: "Амжилттай",
                    message: "Амжилттай хадгалагдлаа",
                    type: "success"
                  });
                }
              });
          } else if (type === "is_available") {
            service
              .updateLatePayStep(data.id, { is_available: data.is_available })
              .then(res => {
                if (res?.data?.status === "success") {
                  this.getLatePaySteps();
                  this.$notify({
                    title: "Амжилттай",
                    message: "Амжилттай хадгалагдлаа",
                    type: "success"
                  });
                }
              });
          }
        })
        .catch(() => {});
    },
    getLatePayStepById(id) {
      this.$router.push({
        path: "edit-late-pay-step",
        query: {
          id: id
        }
      });
      this.$notify({
        title: "Амжилттай",
        message: id + " " + "мэдээллийг засах",
        type: "success"
      });
    },
    deleteLatePayStep(id) {
      this.$confirm("Та устгахдаа итгэлтэй байна уу?", "Анхааруулга", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      }).then(() => {
        service.deleteLatePayStep(id).then(res => {
          if (res?.data?.status === "success") {
            this.getLatePaySteps();
            this.$notify({
              title: "Амжилттай",
              message: id + " " + "мэдээллийг устгалаа",
              type: "success"
            });
          }
        });
      });
    },
    async getLatePaySteps() {
      try {
        this.isDataLoading = true;
        service.getLatePaySteps().then(res => {
          if (
            res?.data?.status === "success" &&
            res?.data?.data?.data &&
            res?.data?.data?.data?.length > 0
          ) {
            this.pageCounts = res.data.data.total_page;
            this.currentPage = res.data.data.current_page;
            this.latePaySteps = res.data.data.data;
          }
        });
        this.isDataLoading = false;
      } catch (err) {
        this.isDataLoading = false;
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async getAllLoan() {
      try {
        this.loading = true;
        service
          .getAllLoan(
            this.numberSearch,
            this.filterValue === "ALL" ? "" : this.filterValue,
            this.size,
            this.loanPage
          )
          .then(res => {
            if (
              res?.data?.status === "success" &&
              res?.data?.data?.data &&
              res?.data?.data?.data?.length > 0
            ) {
              this.loanTotal = res.data.data.total;
              this.loanAllData = res.data.data.data;
            } else {
              this.loanAllData = [];
            }
          });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },

    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },

    setOneItem(item) {
      this.dialogOneItem = item;
      this.dialogVisible = true;
    },

    loanUpdate() {
      const payload = {
        balance: this.input,
        account_id: this.dialogOneItem?.UserAccount?.id,
        loan_status: this.dialogSelectValue,
        description: this.textarea,
        user_name: this.userName
      };
      service.loanUpdate(this.dialogOneItem.id, payload).then(res => {
        if (res.data.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: res.data.message,
            type: "success"
          });
          this.dialogVisible = false;
          this.getAllLoan();
          (this.input = ""),
            (this.dialogSelectValue = ""),
            (this.textarea = "");
        } else {
          this.$notify({
            title: "Амжилтгүй",
            message: res.data.message,
            type: "warning"
          });
        }
      });
    },

    setLoanPage(val) {
      this.loanPage = val * 10 - 10;
      this.getAllLoan();
    },

    selectFilter() {
      this.loanPage = 0;
      this.getAllLoan();
    },

    setPage(val) {
      this.page = val;
    },

    sizeChange(val) {
      this.pageSize = val;
    }
  }
};
</script>
